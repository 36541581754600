import { useAppSelector } from "../../../redux/store";
import { SetStateAction, useEffect, useState } from "react";
import { serviceConfig } from '../redux/cloudAppAPI'
import { serviceConfigInterface, FormikPropsInterface, FieldConfigInterface, serviceRunsDataInterface } from "../interfaces/serviceRunsInterfaces"
import * as yup from "yup";
import Reference from "yup/lib/Reference";
import Lazy from "yup/lib/Lazy";
import { useServiceRunsContext } from '../context/ServiceRuns-context'
import { FormikProps } from "formik";
import { cloneDeep } from "lodash";
import { useGlobalContext } from '../../../../app/components/GlobalContext/GlobalContext'
import { useGetBUOptions } from '../../../../app/hooks/useGetBU'
import { useLocation, useNavigate } from "react-router-dom";

export const useGetCurrentServiceConfig = (serviceID: string) => {
  const [currentServiceConfig, setCurrentServiceConfig] = useState<serviceConfigInterface>()
  const { data } = useAppSelector((state) => serviceConfig(state))

  useEffect(() => {
    if (data && serviceID !== "default") {
      let currentServiceConfig = data.filter(funcConfig => funcConfig.service_descriptive_name === serviceID)[0]
      setCurrentServiceConfig(currentServiceConfig)
    }
  }, [data, serviceID])

  return currentServiceConfig
}

// a function returning default value for the different types of form components
const getFieldDefaultValue = (propType: string) => {
  let defaultValue;
  switch (propType) {
    case "Input":
      defaultValue = "";
      break;
    case "Select":
      defaultValue = "default";
      break;
    case "QuerySelect":
      defaultValue = "default";
      break;
    case "Calendar":
      defaultValue = "";
      break;
    case "RangeCalendar":
      defaultValue = [null, null];
      break;
    case "AzureADSelect":
      defaultValue = "";
      break;
    case "AutocompleteComponent":
      defaultValue = [];
      break;
    case "FileUpload":
      defaultValue = [];
      break;
    case "DGDatePicker":
      defaultValue = null;
      break;
    case "Global":
      defaultValue = "default";
      break;
    case "HiddenArray":
      defaultValue = [];
      break;
    default:
      defaultValue = "";
  }
  return defaultValue;
}

// set service default values
export const useSetDefaultValues = (currentServiceConfig: serviceConfigInterface) => {

  const [defaultValuesState, setDefaultValuesState] = useState<{}>({})
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()

  useEffect(() => {
    // this hook is called very early out of formik and execute only
    // if currentServiceConfig change when a new service is chosen from the menu
    if (currentServiceConfig?.field_config) {
      if (JSON.stringify(currentServiceConfig) !== JSON.stringify(lastServiceConfig)) {
        setLastServiceConfig(currentServiceConfig)
        // create a local object where the keys are prop IDs and values are prop default values
        let defaultValuesObject: { [key: string]: string | {} | null } = {};
        currentServiceConfig.field_config.forEach(prop => {
          // replace the field config of all fields with typeDependency from another field
          // with thier default value
          if ("typeDependency" in prop && prop.typeDependency !== undefined && "fields" in prop) {
            //get the default value of the field which has typeDependency from
            const defaultDepFieldValue = defaultValuesObject[prop.typeDependency.field] as string
            prop = prop["fields"]![defaultDepFieldValue]
          }
          if (prop.type === "automatic") {
            defaultValuesObject[prop.id] = prop.value!
          } else if (["CommonQuery", "RowButton"].includes(prop.type)) {
            // this type should not have a value in formik
          } else {
            defaultValuesObject[prop.id] = getFieldDefaultValue(prop.type)

          }
        })
        setDefaultValuesState(defaultValuesObject)
      }
    }

  }, [currentServiceConfig]);

  return defaultValuesState;
}

export const useSetYup = () => {
  const { currentServiceConfig } = useServiceRunsContext();
  const [yupObject, setYupObject] = useState<{}>(yup.object().shape({
    serviceID: yup.string()
      .required(`Function is a reqired field!`)
      .notOneOf(['default'], "This is a required field!"),
    jsonID: yup.object().shape({})
  }))
  const [localYupObj, setLocalYupObj] = useState<yupObjectInterface>({})
  const [lastServiceConfig, setLastServiceConfig] = useState(currentServiceConfig)
  interface yupObjectInterface {
    [key: string]: yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>
  }

  useEffect(() => {
    if (currentServiceConfig) {
      if (JSON.stringify(currentServiceConfig) !== JSON.stringify(lastServiceConfig) || Object.keys(localYupObj).length === 0) {
        let localYupObject: yupObjectInterface = {};

        currentServiceConfig.field_config.forEach(prop => {
          if (prop.mandatory) {
            if (prop.validator?.type === "string") {
              localYupObject[prop.id] = yup.string()
                .required(`${prop.label} is a reqired field!`)
                .min(+prop.validator?.values.split("-")[0], `Should be at least ${prop.validator?.values.split("-")[0]} long!`)
                .max(+prop.validator?.values.split("-")[1], `Cannot be more than ${prop.validator?.values.split("-")[1]} long!`)
            } else if (prop.validator?.type === "number") {
              localYupObject[prop.id] = yup.number().typeError("Must be a number")
                .required(`${prop.label} is a reqired field!`)
                .min(+prop.validator?.values.split("-")[0], `Should be above ${prop.validator?.values.split("-")[0]}!`)
                .max(+prop.validator?.values.split("-")[1], `Should be below ${prop.validator?.values.split("-")[1]}!`)
            } else if (prop.validator?.type === "object") {
              localYupObject[prop.id] = yup.object()
                .required(`${prop.label} is a reqired field!`)
            } else if (prop.validator?.type === "object array") {
              localYupObject[prop.id] = yup.array()
                .of(yup.object()
                  .required(`${prop.label} is a reqired field!`))
                .required(`${prop.label} is a reqired field!`)
                .min(1, `You should pick at least 1 ${prop.label}`)
            } else if (prop.validator?.type === "array") {
              localYupObject[prop.id] = yup.array()
                .required(`${prop.label} is a reqired field!`)
                .min(1, `You should pick at least 1 ${prop.label}`)
            } else if (prop.validator?.type === "daterange") {
              localYupObject[prop.id] = yup.array().of(
                yup.date().typeError("You should pick start and end date")
                  .required(`${prop.label} is a reqired field!`))
                .required(`${prop.label} is a reqired field!`)
                .test("Number Test", "Please choose from and to date in one and the same year", function (value) {
                  if (prop.validator?.sameYear && Array.isArray(value)) {
                    const [from, to] = value;
                    if ((new Date(from!)).getFullYear() !== (new Date(to!)).getFullYear()) {
                      return false
                    }
                    return true;
                  } else {
                    return true
                  }
                })
              // .min(2, `You should pick start and end date2`)
            } else if (prop.validator?.type === "LookupTable") {
              prop.validator!.fields.forEach((field, index) => {
                localYupObject[field.name] = yup.array().of(
                  yup.string().typeError("You should pick products")
                    .required(`${prop.ltfields[index].label} is a reqired field!`)
                    .test("Sum 100 % Test", "Please select sum of 100%", function (value) {

                      if (field.sumHundredPercent) {

                        if (value && !Number.isNaN(+value) && +value < 0) {
                          return this.createError({ message: "Value should be greater than 0" })
                        }
                        if (value && Number.isNaN(+value)) {
                          return this.createError({ message: "Should be number" })
                        }
                        if (this.parent.length < 2) {
                          return this.createError({ message: "At least 2 elements are required" })
                        }

                        if (!this.parent.some((el: string) => Number.isNaN(+el)) &&
                          !this.parent.some((el: string) => +el < 0) &&
                          this.parent.reduce((accumulator: number, currentValue: number) => (+accumulator + +currentValue), 0) !== 100) {

                          return this.createError({ message: `The sum should be 100%` })
                        }
                      }
                      return true
                    }))
              })
            } else if (prop.validator?.type === "CombinedFields") {
              prop.validator!.fields.forEach(field => {
                localYupObject[field.name] = yup.string()
                  .required(`${prop.label} is a reqired field!`)
              })
            } else {
              localYupObject[prop.id] = yup.string()
                .required(`${prop.label} is a reqired field!`)
                .notOneOf(['default'], "This is a required field!")
            }
          } else {
            if (prop.validator?.type === "string") {
              localYupObject[prop.id] = yup.string()
                .min(+prop.validator?.values.split("-")[0], `Should be at least ${prop.validator?.values.split("-")[0]} long!`)
                .max(+prop.validator?.values.split("-")[1], `Cannot be more than ${prop.validator?.values.split("-")[1]} long!`)
            } else if (prop.validator?.type === "number") {
              localYupObject[prop.id] = yup.number()
                .min(+prop.validator?.values.split("-")[0], `Should be above ${prop.validator?.values.split("-")[0]}!`)
                .max(+prop.validator?.values.split("-")[1], `Should be below ${prop.validator?.values.split("-")[1]}!`)
            }
          }
        })
        setYupObject(yup.object().shape({
          serviceID: yup.string()
            .required(`Function is a reqired field!`)
            .notOneOf(['default'], "This is a required field!"),
          jsonID: yup.object().shape(localYupObject)
        }))
        setLocalYupObj(localYupObject)
        setLastServiceConfig(currentServiceConfig)
      }
    }
  }, [currentServiceConfig])

  return yupObject
}

// when queries result is huge amount of data it cause slowness. 
//this is why it is removed and put in the context api
export const useExtractCommonQueries = (currentServiceConfig: serviceConfigInterface) => {
  const { setCommonQueries } = useServiceRunsContext();
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        setLastServiceConfig(currentServiceConfig);
        if ("common_queries" in currentServiceConfig) {
          setCommonQueries(currentServiceConfig.common_queries)
          setEstimatedServiceConfig({ ...currentServiceConfig, common_queries: {} })
        } else {
          setEstimatedServiceConfig({ ...currentServiceConfig })
        }
      }
    }

  }, [currentServiceConfig])

  return estimatedServiceConfig;
}

export const useTypeDependencyReturnField = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const [depFields, setDepFields] = useState<Array<{ [key: string]: string }>>([])
  const isDepFieldsSet = depFields.length !== 0
  const { values } = formik;

  //set depFields array initially through isDepFieldsSet and only of service change
  //each element is an object with a key the ID of the field which other field depends on and a value its value
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      if (currentServiceConfig.service_descriptive_name !== values.serviceID) {
        setDepFields([])
      }
      const isValuesSet = Object.keys(values.jsonID).length > 0
      if (isValuesSet && !isDepFieldsSet) {
        currentServiceConfig.field_config.forEach(entry => {
          if ("typeDependency" in entry && entry.typeDependency !== undefined) {
            const depFieldName = entry.typeDependency.field
            setDepFields(prev => [...prev, { key: depFieldName, value: values.jsonID[depFieldName] as string }])
          }
        })
      }
    }

  }, [currentServiceConfig, values])

  // we use depFields in order to escape changing currentConf on every value change
  useEffect(() => {
    //if depFields is not set we set this isDepFieldsChanged to true to estimate estimatedServiceConfig in the next if for the first time
    //then it is estimated only if the prop values in depFields changed
    if (currentServiceConfig?.field_config) {
      //here we check if the fields values in depFields has changed and if changed we update depFields too with the new values
      const isDepFieldsChanged = isDepFieldsSet ? depFields.some(entry => {
        const isCurrentDepFieldChanged = values.jsonID[entry.key] !== entry.value
        if (isCurrentDepFieldChanged) {
          setDepFields(prev => [...(prev.filter(field => field.key !== entry.key)), { key: entry.key, value: values.jsonID[entry.key] as string }])
        }
        return isCurrentDepFieldChanged;
      }) : true

      //only if the fields values in depFields has changed we estimate new estimatedServiceConfig
      if (isDepFieldsChanged) {
        const resolvedTypeDepConfig = currentServiceConfig.field_config.map(entry => {
          if ("typeDependency" in entry && entry.typeDependency !== undefined && "fields" in entry) {
            // if the field has typeDependency prop we replace its config with some of the configs in
            // fields property depending on the field which has typeDependency from
            const currentValue = values.jsonID[entry.typeDependency.field] as string;
            return currentValue ? entry.fields![currentValue] : entry.fields!["default"]
          }
          return entry;
        })
        setEstimatedServiceConfig({ ...currentServiceConfig, field_config: resolvedTypeDepConfig })
      }
    }

  }, [values, currentServiceConfig, depFields])

  return estimatedServiceConfig;
}

export const useSetOptionsBuilderOptions = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()
  const [monitoredValues, setMonitoredValues] = useState<{ [key: string]: string }>({})
  const { values } = formik;
  const { commonQueries } = useServiceRunsContext();

  // create an object which contains all fields which have some other field which options depend on it
  // for each field this info is saved in optionsBuilder!.dependency
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        currentServiceConfig?.field_config.forEach(item => {
          if ("optionsBuilder" in item && "dependency" in item.optionsBuilder!) {
            item.optionsBuilder!.dependency!.forEach(dep => {
              if (!Object.keys(monitoredValues).includes(dep.fieldname)) {
                const localOBj = { [dep.fieldname]: values.jsonID[dep.fieldname] as string }
                setMonitoredValues(prev => ({ ...prev, ...localOBj }))
              }
            })
          }

        })
      }
    }

  }, [currentServiceConfig])

  useEffect(() => {
    //check if some of the fields in monitoredValues changed in order to reestimate later the options of the select element which depends on them
    const monitoredValuesChanged = Object.keys(monitoredValues).some(el => {
      const differentValue = values.jsonID[el] !== String(monitoredValues[el])
      if (differentValue) {
        setMonitoredValues(prev => ({ ...prev, [el]: String(values.jsonID[el]) }))
        return true
      } else {
        return false
      }
    })
    if (currentServiceConfig?.field_config) {
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig) || monitoredValuesChanged) {
        setLastServiceConfig(currentServiceConfig)
        const resolvedTypeDepConfig = cloneDeep(currentServiceConfig).field_config.map(entry => {
          // if the field conatins optionsBuilder prop its options are estimated in the code below
          if ("optionsBuilder" in entry) {
            const optionsQuery = commonQueries![entry.optionsBuilder!.query_name]
            entry.options = []
            //if the field depend on other fields in the map, first the map is filtered with fields values which depends on
            // when we click from the menu between different services, sometimes optionsQuery is not defined
            if (optionsQuery) {
              let filteredOptionsQuery = optionsQuery;
              //if the field deosn't depend on other fields its options are calculated directly from the map
              if ("dependency" in entry.optionsBuilder!) {
                entry.optionsBuilder!.dependency!.forEach(dep => {
                  let filter: Array<string> = [];
                  if (Array.isArray(values.jsonID[dep.fieldname!])) {
                    let arr = values.jsonID[dep.fieldname!] as Array<{ [key: string]: string }>
                    if (arr.length > 0) filter = arr.map(el => el.value)
                  } else {
                    filter = [values.jsonID[dep.fieldname!] as string]
                  }
                  filteredOptionsQuery = filteredOptionsQuery.filter(item => filter.includes(item[dep.mapname!]))
                })
              }
              filteredOptionsQuery.forEach(item => {
                if (!entry.options?.map(mem => mem.name).includes(item[entry.optionsBuilder!.optionsMap.name])) {
                  const name = entry.optionsBuilder!.includeValueInName ? item[entry.optionsBuilder!.optionsMap.name] + ' - ' + String(item[entry.optionsBuilder!.optionsMap.value]) : item[entry.optionsBuilder!.optionsMap.name]
                  entry.options?.push({ name: name, value: String(item[entry.optionsBuilder!.optionsMap.value]) })
                }
              })
            }
          }
          return entry;
        })
        setEstimatedServiceConfig({ ...currentServiceConfig, field_config: resolvedTypeDepConfig })
      }
    }
  }, [currentServiceConfig, values])

  return estimatedServiceConfig
}
// const resetLooukupTableFileds = (ltfields: FieldConfigInterface[], formik: FormikProps<FormikPropsInterface>) => {

//   ltfields.forEach(field => {
//     const fieldID = field.id;
//     if ("combinedfields" in field) {
//       field.combinedfields.forEach(cf => formik.setFieldValue(`jsonID.${cf}`, [""]))
//     } else {
//       formik.setFieldValue(`jsonID.${fieldID}`, [""])
//     }
//   })

// }

// we moved useResetFields before useShowDependencyFieldDefault to escape the case where delete formik prop is recreated
export const useResetFields = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>(currentServiceConfig)
  const [resetFieldsMap, setResetFieldsMap] = useState<Array<{ id: string, resetFields: string }>>()
  const [lastValues, setlastValues] = useState<{ [key: string]: { [key: string]: string } | string | Array<{ [key: string]: string }> | string[] }>(formik.values.jsonID)

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        setLastServiceConfig(currentServiceConfig)
        // we created an array of objects with id prop equal to the prop id which change in its value will cause reset and resetFields prop equal to the comma separated list of the fields ids which values will be reset
        setResetFieldsMap(currentServiceConfig.field_config.filter(entry => "resetFields" in entry).map(entry => (
          { id: entry.type === "ModalInput" ? entry.combinedfields[1] : entry.id, resetFields: entry.resetFields! }
        )))
        // set last formik values when the config changed
        setlastValues(formik.values.jsonID)
      }
      // if the service has been changed we reset setResetFieldsMap
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setResetFieldsMap([])
      }
    }


  }, [currentServiceConfig, formik.values])

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // ensure that service config is for the same service like in formik
      if (currentServiceConfig.service_descriptive_name === formik.values.serviceID) {
        resetFieldsMap?.forEach(entry => {
          if (formik.values.jsonID[entry.id] !== lastValues[entry.id] && Object.keys(lastValues).length > 0) {
            //set last formik values when some of the fields in resetFieldsMap changed
            setlastValues(formik.values.jsonID)
            // we loop through the all fields which will be reset
            entry.resetFields.split(",").forEach(item => {
              const resetFieldConfig = currentServiceConfig.field_config.filter(el => el.id === item)
              if (resetFieldConfig.length > 0) {
                if (resetFieldConfig[0].type === "LookupTable") {
                  resetFieldConfig[0].ltfields.forEach(field => {
                    if (field.type === "ModalInput") {
                      field.combinedfields.forEach(cf => formik.setFieldValue(`jsonID.${cf}`, [""]))
                    } else {
                      formik.setFieldValue(`jsonID.${field.id}`, [""])
                    }
                  })
                } else {
                  formik.setFieldValue(`jsonID.${item}`, getFieldDefaultValue(resetFieldConfig[0].type))
                  formik.setFieldTouched(`jsonID.${item}`, false)
                }

              }
            })
          }
        })
      }
    }
  }, [resetFieldsMap, formik.values, lastServiceConfig])
}


export const useShowDependencyFieldDefault = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const [showDependencyFieldsArray, setShowDependencyFieldsArray] = useState<Array<{ key: string, formikValue: string }>>([])
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>(currentServiceConfig)

  // create showDependencyFieldsArray which later is used to reset field if changed
  // it is checked for changes only if config change
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // if the service change we reset showDependencyFieldsArray
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setShowDependencyFieldsArray([])
      }
      // put new members in showDependencyFieldsArray if config changed
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        setLastServiceConfig(currentServiceConfig)
        currentServiceConfig.field_config.forEach(item => {
          if ("showDependency" in item && item.showDependency !== undefined) {
            // skip this cycle if the field already in the array
            if (showDependencyFieldsArray.filter(el => el.key === item.showDependency!.field).length > 0) return;
            setShowDependencyFieldsArray(prev => {
              return [...prev, { key: item.showDependency!.field, formikValue: formik.values.jsonID[item.showDependency!.field] as string }]
            })
          }
        });
      }
    }
  }, [formik.values, currentServiceConfig])

  //set new config if some of the fields in showDependencyFieldsArray changed in formik
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // check for value changes in the fields in showDependencyFieldsArray and change the array if there are changes
      let newShowDependencyFieldsArray: Array<{ key: string, formikValue: string }> = []
      let arrayChanged = false;
      showDependencyFieldsArray.forEach(el => {
        if (formik.values.jsonID[el.key] !== el.formikValue) {
          arrayChanged = true
          newShowDependencyFieldsArray = [...showDependencyFieldsArray.filter(g => g.key !== el.key), { key: el.key, formikValue: formik.values.jsonID[el.key] as string }]
        }
      })
      if (arrayChanged) setShowDependencyFieldsArray(newShowDependencyFieldsArray);
      //if showDependencyFieldsArray changed here or conf changed set new estimatedServiceConfig
      if (arrayChanged || JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        setEstimatedServiceConfig({
          ...currentServiceConfig, field_config: currentServiceConfig.field_config.filter(item => {
            if ("showDependency" in item && item.showDependency !== undefined) {
              if (formik.values.jsonID[item.showDependency.field] === item.showDependency.value) {
                return true
              } else if (item.showDependency.hasOneItem && formik.values.jsonID[item.showDependency.field].length === 1) {
                return true
              } else {
                // we filter out the field if the value of the field in showDependency prop is not equal to the value in formik
                // we also set it as undefined - remove it from formik
                formik.setFieldValue(`jsonID.${item.id}`, undefined)
                return false
              }
            } else {
              return true;
            }
          })
        })
      }
    }

  }, [formik.values, currentServiceConfig, showDependencyFieldsArray])

  return estimatedServiceConfig;
}

export const useSetDisabledFields = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const { setCurrentServiceConfig } = useServiceRunsContext();
  const [disabledFieldsArray, setDisabledFieldsArray] = useState<Array<{ key: string, formikValue: string }>>([])
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()

  //set disabledFieldsArray which will be use in the next useEffect as a condition to re-esitimate config
  //it is an array of objects with prop key equals to the id of the prop which keep the current field disabled 
  //and formikValue prop equals to comma separated list of the values of the locker field which keeps the current field disabled 
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // reset disabledFieldsArray if service changes
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setDisabledFieldsArray([])
      }
      //re-estimate disabledFieldsArray on currentServiceConfig change
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        //set last currentConf if change 
        setLastServiceConfig(currentServiceConfig);
        const disabledFieldsArray: Array<{ key: string, formikValue: string }> = []
        currentServiceConfig.field_config.forEach(item => {
          if ("disableDependency" in item && item.disableDependency !== undefined) {
            item.disableDependency.forEach(el => disabledFieldsArray.push({ key: el.field, formikValue: formik.values.jsonID[el.field] as string }))
          }
        });
        setDisabledFieldsArray(disabledFieldsArray)
      }
    }

  }, [currentServiceConfig, formik.values.serviceID])

  // we change the config only if some of the fields in disabledFieldsArray has changed or the current config changed
  useEffect(() => {
    // check if the values in disabledFieldsArray has changed
    const disabledFieldsArrayChanged = disabledFieldsArray.filter(el => el.formikValue !== formik.values.jsonID[el.key] as string).length > 0
    if (currentServiceConfig?.field_config) {
      if (disabledFieldsArrayChanged || JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        // if disabledFieldsArray changed we set the new value
        if (disabledFieldsArrayChanged) {
          setDisabledFieldsArray(disabledFieldsArray.map(el => ({ key: el.key, formikValue: formik.values.jsonID[el.key] as string })))
        }
        // if the new values includes some of the disabled values we set a new property named disabled in the field config
        const getServiceConfig = {
          ...currentServiceConfig, field_config: currentServiceConfig.field_config.map(item => {
            if ("disableDependency" in item && item.disableDependency !== undefined) {
              // item.disableDependency.forEach(el => {
              let disabled = false;
              for (const el of item.disableDependency) {
                if (Array.isArray(formik.values.jsonID[el.field])) {
                  if (formik.values.jsonID[el.field].length === 0) {
                    disabled = true;
                    break;
                  }
                } else if (formik.values.jsonID[el.field] === null) {
                  disabled = true;
                  break;
                } else {
                  if (el.value.split(",").includes(formik.values.jsonID[el.field] as string)) {
                    disabled = true;
                    break;
                  }
                }

                // });
              }
              item.disabled = disabled;
            }
            return item;
          })
        }
        setEstimatedServiceConfig(getServiceConfig)
        // change the config in context api
        setCurrentServiceConfig(getServiceConfig)
      }

    }

  }, [formik.values, currentServiceConfig, disabledFieldsArray])

  return estimatedServiceConfig;
}

export const useTypeDependencyFieldDefault = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const { values, setFieldValue } = formik;
  const [typeDependencyFieldsArray, setTypeDependencyFieldsArray] = useState<Array<{ key: string, value: string, id: string }>>([])

  // create typeDependencyFieldsArray which contains all fields with typedependency on another field. 
  // If some these fields value changed later we change to default value the field which has typedependecy of it 
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // reset typeDependencyFieldsArray if service change
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setTypeDependencyFieldsArray([])
      } else {
        currentServiceConfig.field_config.forEach(prop => {
          if ("typeDependency" in prop && prop.typeDependency !== undefined && typeof values.jsonID === 'object' && !Array.isArray(values.jsonID)) {
            //create an object with prop named key with value equals to the ID of the field which value change the type of the current field
            // with prop named value equal to the value of the upper field and a prop id with value the id of type dependant field 
            const typeDependancyObject: { key: string, value: string, id: string } = {
              key: prop.typeDependency.field,
              value: values.jsonID[prop.typeDependency.field] as string ?? "default",
              id: prop.id
            }
            setTypeDependencyFieldsArray(prev => {
              // check if current field is already in typeDependencyFieldsArray. If yes we modify it directly if not we add it
              let currentValue = cloneDeep(prev)
              let typeDepAlreadyIn = false;
              for (const obj of currentValue) {
                if (typeDependancyObject.id === obj.id) {
                  typeDepAlreadyIn = true;
                  obj.value = typeDependancyObject.value;
                }
              }
              return typeDepAlreadyIn ? currentValue : [...currentValue, typeDependancyObject]
            })

          }
        })
      }

    }
  }, [values, currentServiceConfig])

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      if (currentServiceConfig.service_descriptive_name === formik.values.serviceID) {
        typeDependencyFieldsArray.forEach(field => {
          // If some of the values of typeDependencyFieldsArray changed we change to default value the field which has typedependecy of it
          if (values.jsonID && field.key in values.jsonID && field.value !== values.jsonID[field.key]) {
            const currentValue = values.jsonID[field.key] as string
            const defautValue = getFieldDefaultValue(currentServiceConfig.field_config.filter(item => item.id === field.id)[0].fields![currentValue].type)
            setFieldValue(`jsonID.${field.id}`, defautValue)
          }
        })
      }
    }
  }, [values, typeDependencyFieldsArray])

}

export const useEstimateComputedField = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  //we keep last values of all ComputedSelectName field in lastComputedValue
  const [lastComputedValue, setLastComputedValue] = useState<{ [key: string]: string }>({})
  const buOptions = useGetBUOptions();

  useEffect(() => {
    if (currentServiceConfig?.field_config && formik.values.jsonID) {
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setLastComputedValue({})
      } else {
        currentServiceConfig.field_config.forEach(el => {
          if (el.type === "ComputedSelectName") {
            if ("otherFieldName" in el && el.otherFieldName !== undefined) {
              // we get the options of the select field which ComputedSelectName field get its value

              let computedValue: { name: string; value: string | number; };
              if (el.otherFieldName === 'bu') {
                computedValue = buOptions.filter(item => item.value === formik.values.jsonID[el.otherFieldName!])[0]
              } else {
                const otherFieldOptions = currentServiceConfig.field_config.filter(item => item.id === el.otherFieldName)[0].options
                computedValue = otherFieldOptions?.filter(item => item.value === formik.values.jsonID[el.otherFieldName!])[0]!
              }

              // if the value change we set it in formik and in lastComputedValue
              if (computedValue && lastComputedValue[el.id] !== computedValue.name) {
                formik.setFieldValue(`jsonID.${el.id}`, computedValue.name)
                setLastComputedValue(prev => ({ ...prev, [el.id]: computedValue.name }))
              }
            }
          }
        })
      }
    }
  }, [formik.values, currentServiceConfig])
}

export const useSetBU = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  //we keep last values of all ComputedSelectName field in lastComputedValue
  const { bu } = useGlobalContext();

  useEffect(() => {
    if (currentServiceConfig?.field_config && formik.values.jsonID) {
      // if(currentServiceConfig?.field_config.some(fc => "module_id" === fc.id)) {
      if (bu !== formik.values.jsonID.bu) {
        formik.setFieldValue('jsonID.bu', bu)
      }
      // }

    }
  }, [bu, currentServiceConfig?.field_config, formik.values.jsonID.bu])
}

export const useGetTableEditNewFields = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  //we keep last values of all ComputedSelectName field in lastComputedValue
  const { editTable, newRow } = useServiceRunsContext();
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()

  useEffect(() => {

    if (currentServiceConfig?.field_config) {
      if (editTable || newRow) {
        const isDataTableType = currentServiceConfig?.field_config.filter(fc => fc.type === "TableField")
        if (isDataTableType.length > 0) {
          const newFieldConfig = editTable ? [...isDataTableType[0].data_fields.filter(item => item.inEdit === true)] :
            [...isDataTableType[0].data_fields.filter(item => item.inNew === true)]
          setEstimatedServiceConfig({
            ...currentServiceConfig,
            field_config: [...newFieldConfig,
            ...currentServiceConfig.field_config.filter(f => f.type === "automatic")]
          })
        } else {
          setEstimatedServiceConfig(currentServiceConfig)
        }

      } else {
        setEstimatedServiceConfig(currentServiceConfig)
      }
    }

  }, [editTable, currentServiceConfig?.field_config, newRow])

  return estimatedServiceConfig;
}

export const useResetEditTableNewRowOnBUChange = () => {
  const { setEditTable, setNewRow, newRow, editTable, setTableContentReady } = useServiceRunsContext();
  const { bu } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (newRow || editTable) {
      setEditTable(false);
      setNewRow(false);
      setTableContentReady(false);
      navigate(currentPath);
    }

  }, [bu])
}

// on back browser button reset table edit and new
export const useResetEditTableOnState = (state: serviceRunsDataInterface) => {
  const { setEditTable, setNewRow, setTableContentReady } = useServiceRunsContext();

  useEffect(() => {
    if (state === null) {
      setEditTable(false);
      setNewRow(false);
      setTableContentReady(false);
    }

  }, [state])
}

export const useSetTableNewFieldsDefaults = (data_fields: FieldConfigInterface[]) => {
  const [defaultValues, setDefaultValues] = useState<{ [key: string]: string | string[] }>()

  useEffect(() => {
    let localDefaultValues: { [key: string]: string | string[] } = {};
    if (data_fields) {
      data_fields.filter(item => item.inNew).forEach(item => {
        if (item.type === "ModalInput") {
          item.combinedfields.forEach(cf => localDefaultValues[cf] = '')
        } else if (item.type === "LookupTable") {
          item.ltfields.forEach(ltf => {
            if (ltf.type === "LTInput") {
              localDefaultValues[ltf.id] = [""]
            } else if (ltf.type === "ModalInput") {
              ltf.combinedfields.forEach(cf => localDefaultValues[cf] = [""])
            }
          })
        } else if (item.type === "Select") {
          localDefaultValues[item.id] = "default";
        } else if (item.type === "AutocompleteComponent") {
          localDefaultValues[item.id] = [];
        } else {
          localDefaultValues[item.id] = "";
        }
      })
    }



    setDefaultValues(localDefaultValues)
  }, [data_fields])

  return defaultValues;
}

export const useQueryShowDependency = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const [queryShowDependencyFieldsArray, setQueryShowDependencyFieldsArray] = useState<Array<{ params: Array<{ fieldname: string, mapname: string }>, ready: boolean }>>([])
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>(currentServiceConfig)
  const { commonQueries } = useServiceRunsContext();

  // create showDependencyFieldsArray which later is used to reset field if changed
  // it is checked for changes only if config change
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // if the service change we reset showDependencyFieldsArray
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setQueryShowDependencyFieldsArray([]);
      }
      // put new members in showDependencyFieldsArray if config changed
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        setLastServiceConfig(currentServiceConfig);
        // setEstimatedServiceConfig(currentServiceConfig)
        const currentQueryShowDependencyFieldsArray: Array<{ params: Array<{ fieldname: string, mapname: string }>, ready: boolean, }> = [];
        currentServiceConfig.field_config.forEach(item => {
          if (item.queryShowDependency !== undefined) {
            // skip this cycle if the field already in the array
            if (currentQueryShowDependencyFieldsArray.filter(el =>
              JSON.stringify(el.params.sort((a, b) => a.fieldname.localeCompare(b.fieldname))) ===
              JSON.stringify(item.queryShowDependency.params.sort((a, b) => a.fieldname.localeCompare(b.fieldname)))).length === 0) {
              currentQueryShowDependencyFieldsArray.push({ params: item.queryShowDependency.params.sort((a, b) => a.fieldname.localeCompare(b.fieldname)), ready: false })
            }

          }
        });
        setQueryShowDependencyFieldsArray(currentQueryShowDependencyFieldsArray);
      }
    }


  }, [currentServiceConfig])

  // //set new config if some of the fields in showDependencyFieldsArray changed in formik
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // check for value changes in the fields in showDependencyFieldsArray and change the array if there are changes
      // if(!estimatedServiceConfig) setEstimatedServiceConfig(currentServiceConfig)
      if (queryShowDependencyFieldsArray.length > 0) {
        queryShowDependencyFieldsArray.forEach((query, index) => {
          if (query.params.every(param => formik.values.jsonID[param.fieldname] !== "default")) {
            // const currentArray = [...queryShowDependencyFieldsArray]
            // currentArray[index] = { ...query, ready: true }
            setQueryShowDependencyFieldsArray(prev => {
              const currentArray = [...prev];
              currentArray[index] = { ...query, ready: true };
              return currentArray;
            })
          }
        })
      }


    }

  }, [formik.values, currentServiceConfig])

  useEffect(() => {


    if (currentServiceConfig?.field_config) {
      // if (queryShowDependencyFieldsArray.some(query => query.ready)) {
      setEstimatedServiceConfig({
        ...currentServiceConfig, field_config: currentServiceConfig.field_config.filter(item => {
          if (item.queryShowDependency !== undefined) {
            let currentQuery = queryShowDependencyFieldsArray.filter(query => JSON.stringify(query.params.sort((a, b) => a.fieldname.localeCompare(b.fieldname))) ===
              JSON.stringify(item.queryShowDependency.params.sort((a, b) => a.fieldname.localeCompare(b.fieldname))))[0];
            if (currentQuery?.ready) {
              // let optionsQuery = commonQueries![item.queryShowDependency.query_name]
              // currentQuery.params.forEach(param => {
              //   optionsQuery = optionsQuery.filter(item => item[param.mapname] === formik.values.jsonID[param.fieldname])
              // })
              // if (optionsQuery.filter(opt => opt[item.queryShowDependency.field] === item.label).length > 0) {
              //   return true
              // } else {
              //   return false;
              // }
              if (commonQueries![item.queryShowDependency.query_name]
                .filter(opt => currentQuery.params.every(param => opt[param.mapname] === formik.values.jsonID[param.fieldname]))
                .filter(opt => opt[item.queryShowDependency.field] === item.label).length > 0) {
                return true
              } else {
                return false;
              }
            } else {
              return false
            }
          } else {
            return true;
          }
        })
      })
    }
    // }
  }, [queryShowDependencyFieldsArray])

  return estimatedServiceConfig;
}

export const useResetFormikOnServiceCahnge = (formik: FormikProps<FormikPropsInterface>, formikInitialValues: { serviceID: string, jsonID: {} }) => {


  useEffect(() => {

    formik.setValues(formikInitialValues)

  }, [formik.values.serviceID])

}


export const useSetConditionalMandatoryFields = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const [conditionalMandatoryArray, setConditionalMandatoryArray] = useState<Array<{ key: string, formikValue: string[] }>>([])
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()

  //set conditionalMandatoryArray which will be use in the next useEffect as a condition to re-esitimate config
  //it is an array of objects with prop key equals to the id of the prop which non-default value (default value is []) set the current field as mandatory 
  //and formikValue prop equals to its value
  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // reset conditionalMandatoryArray if service changes
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setConditionalMandatoryArray([])
      }
      //re-estimate conditionalMandatoryArray on currentServiceConfig change
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        //set last currentConf if change 
        setLastServiceConfig(currentServiceConfig);
        const conditionalMandatoryFieldsArray: Array<{ key: string, formikValue: string[] }> = []
        currentServiceConfig.field_config.forEach(item => {
          if ("conditionalMandatory" in item && item.conditionalMandatory !== undefined) {
            conditionalMandatoryFieldsArray.push({ key: item.conditionalMandatory, formikValue: formik.values.jsonID[item.conditionalMandatory] as string[] })
          }
        });
        setConditionalMandatoryArray(conditionalMandatoryFieldsArray)
      }
    }

  }, [currentServiceConfig, formik.values.serviceID])

  // we change the config only if some of the fields in conditionalMandatoryFieldsArrayChanged has changed or the current config changed
  useEffect(() => {
    // check if the values in conditionalMandatoryFieldsArrayChanged has changed
    // const conditionalMandatoryFieldsArrayChanged = conditionalMandatoryArray.filter(el => el.formikValue && el.formikValue.length === 0 && formik.values.jsonID[el.key] && formik.values.jsonID[el.key].length !== 0).length > 0
    const conditionalMandatoryFieldsArrayChanged = conditionalMandatoryArray.filter(el => JSON.stringify(el.formikValue) !== JSON.stringify(formik.values.jsonID[el.key]) as string).length > 0
    if (currentServiceConfig?.field_config) {
      if (conditionalMandatoryFieldsArrayChanged || JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        // if conditionalMandatoryFieldsArrayChanged changed we set the new value
        if (conditionalMandatoryFieldsArrayChanged) {
          setConditionalMandatoryArray(conditionalMandatoryArray.map(el => ({ key: el.key, formikValue: formik.values.jsonID[el.key] as string[] })))
        }
        // conditionalMandatory filelds works only with array condition fields!!!! If it is empty array it is not mandatory otherwise it is mandatory
        const getServiceConfig = {
          ...cloneDeep(currentServiceConfig), field_config: cloneDeep(currentServiceConfig).field_config.map(item => {
            if ("conditionalMandatory" in item && item.conditionalMandatory !== undefined && formik.values.jsonID[item.conditionalMandatory]) {
              if (+formik.values.jsonID[item.conditionalMandatory].length > 0) {
                item.mandatory = true;
              } else {
                item.mandatory = false;
              }
            }
            return item;
          })
        }
        setEstimatedServiceConfig(getServiceConfig)
      }

    }

  }, [formik.values, currentServiceConfig, conditionalMandatoryArray])

  return estimatedServiceConfig;
}


export const useSetQuerySelectOptions = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const { commonQueries } = useServiceRunsContext();
  const [dynamicSelect, setDynamicSelect] = useState<{ fieldName: string, queryValue: string, queryName: string }[]>([])

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // reset conditionalMandatoryArray if service changes
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setDynamicSelect([])
      }
      //re-estimate conditionalMandatoryArray on currentServiceConfig change
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        //set last currentConf if change 
        setLastServiceConfig(currentServiceConfig);
        currentServiceConfig.field_config.forEach(item => {
          if ("commonQueryName" in item && item.type === "Select") {
            setDynamicSelect(prev => ([...prev, { fieldName: item.id, queryValue: "", queryName: item.commonQueryName }]))
          }
        });
        setEstimatedServiceConfig(currentServiceConfig);
      }
    }
  }, [currentServiceConfig])

  useEffect(() => {

    if (currentServiceConfig?.field_config) {
      if (commonQueries) {
        let localFieldConfig;
        dynamicSelect.forEach(ds => {
          if (ds.queryValue !== JSON.stringify(commonQueries[ds.queryName])) {
            setDynamicSelect(prev =>
              [...prev.filter(el => el.fieldName !== ds.fieldName),
              { ...ds, queryValue: JSON.stringify(commonQueries[ds.queryName]) }]
            )
            localFieldConfig = currentServiceConfig.field_config.map(el => {

              if (el.id === ds.fieldName) {
                el.options = Array.isArray(commonQueries[ds.queryName]) && commonQueries[ds.queryName].length > 0 ? commonQueries[ds.queryName].map(item => ({ name: item.currentkey, value: item.value })) : undefined;
                formik.setFieldValue(`jsonID.${el.id}`, "default");
                formik.setFieldTouched(el.id, false);
              }
              return el;
            })
          }
        })
        if (localFieldConfig) {
          setEstimatedServiceConfig({ ...currentServiceConfig, field_config: localFieldConfig })
        }
      }
    }

  }, [commonQueries])

  return estimatedServiceConfig;
}

export const useHideSelectIfEmpty = (currentServiceConfig: serviceConfigInterface, formik: FormikProps<FormikPropsInterface>) => {
  const [lastServiceConfig, setLastServiceConfig] = useState<serviceConfigInterface>()
  const [estimatedServiceConfig, setEstimatedServiceConfig] = useState<serviceConfigInterface>()
  const { commonQueries } = useServiceRunsContext();
  const [dynamicSelect, setDynamicSelect] = useState<{ fieldName: string, queryValue: string, queryName: string }[]>([])

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      // reset conditionalMandatoryArray if service changes
      if (currentServiceConfig.service_descriptive_name !== formik.values.serviceID) {
        setDynamicSelect([])
      }
      //re-estimate conditionalMandatoryArray on currentServiceConfig change
      if (JSON.stringify(lastServiceConfig) !== JSON.stringify(currentServiceConfig)) {
        //set last currentConf if change 
        setLastServiceConfig(currentServiceConfig);
        currentServiceConfig.field_config.forEach(item => {
          if ("commonQueryName" in item && item.type === "Select" && item.hideIfEmpty) {
            setDynamicSelect(prev => ([...prev, { fieldName: item.id, queryValue: "", queryName: item.commonQueryName }]))
          }
        });
        setEstimatedServiceConfig(currentServiceConfig);
      }
    }
  }, [currentServiceConfig])

  useEffect(() => {

    if (currentServiceConfig?.field_config) {
      if (commonQueries) {
        dynamicSelect.forEach(ds => {
          if (ds.queryValue !== JSON.stringify(commonQueries[ds.queryName])) {
            setDynamicSelect(prev =>
              [...prev.filter(el => el.fieldName !== ds.fieldName),
              { ...ds, queryValue: JSON.stringify(commonQueries[ds.queryName]) }]
            )
            setEstimatedServiceConfig(
              {
                ...currentServiceConfig, field_config: currentServiceConfig.field_config.filter(el => {
                  if (el.id == ds.fieldName && commonQueries[ds.queryName]?.length === 0) {
                    if ("setIfEmpty" in el) {
                      formik.setFieldValue(`jsonID.${el.id}`, el.setIfEmpty)
                    }
                    return false
                  } else {
                    return true
                  }
                }
              )
            })
          }
        })
      }
    }

  }, [commonQueries])

  return estimatedServiceConfig;
}

export const useShowTableButtons = (currentServiceConfig: serviceConfigInterface) => {
  const { editTable, newRow, setTableContentReady } = useServiceRunsContext();

  useEffect(() => {
    if (currentServiceConfig?.field_config) {
      if (newRow || editTable) {
        const isDataTableType = currentServiceConfig?.field_config.filter(fc => fc.type === "TableField")
        if (isDataTableType.length === 0) {
          setTableContentReady(true)
        }
      }
    }
  }, [currentServiceConfig])
}