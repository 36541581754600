import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { TableComponent } from "../../../app/components/table/NewTable";
import { useMemo } from "react";
import { FC } from 'react'
import { useLazyConnectDBQuery, useLazyConnectFunctionQuery } from "../../../app/modules/ServiceRuns/redux/cloudAppAPI";
import { useCreateColumnsObject, useGetColumnInitialState } from '../../../app/modules/ServiceRuns/hooks/TableHooks'
import { useCheckQueryReady, useSetPreQuery } from '../../../app/modules/ServiceRuns/hooks/RoleTableHooks'
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { useGlobalContext } from "../../../app/components/GlobalContext/GlobalContext";
import { useSetTableNewFieldsDefaults } from "../../../app/modules/ServiceRuns/hooks/DynamicServiceHooks";
import { FieldConfigInterface } from "../../../app/modules/ServiceRuns/interfaces/serviceRunsInterfaces";
import { GridRowSelectionModel, GridSortDirection } from "@mui/x-data-grid-pro";

export interface TableFieldInterface {
  field: {
    name: string,
    value: string | number | undefined | number
  },
  form: {
    touched: {},
    errors: { jsonID: { [key: string]: string[] } },
    setFieldTouched: (a: string, b: boolean) => {},
    setFieldValue: (a: string, b: string | number | Array<string | number> | { [key: string]: string }[]) => {},
    values: { serviceID: string, jsonID: { [key: string]: string } },
    initialValues: { jsonID: { [key: string]: string } }
  },
  data_fields: FieldConfigInterface[],
  queryparams: string,
  service_descriptive_name: string,
  newRecordLabel: string,
  hideToolBar: boolean,
  disableTableEdit: boolean,
  checkboxSelection: boolean,
  funcQueryName: string,
  dynamicHeaderExceptions: string[],
  filterBy: string[],
  disableColumnMenu?: boolean,
  disablePagination?: boolean,
  minDateRestriction: {
    property: string,
    query: string,
    depProp: string
  },
  maxDateRestriction: string,
  identityparams: string
}

export const TableField: FC<TableFieldInterface> = ({
  field,
  form,
  data_fields,
  queryparams,
  service_descriptive_name,
  newRecordLabel,
  hideToolBar,
  disableTableEdit,
  checkboxSelection,
  funcQueryName,
  dynamicHeaderExceptions,
  filterBy,
  disableColumnMenu = false,
  disablePagination = false,
  minDateRestriction,
  maxDateRestriction,
  identityparams
}) => {

  const navigate = useNavigate();
  const [trigger, { data, isLoading, isFetching }] = useLazyConnectDBQuery();
  const [funcTrigger, { data: funcData, isLoading: funcIsLoading, isFetching: funcIsFetching }] = useLazyConnectFunctionQuery();
  const { paginationModel,
    setPaginationModel,
    setSortModelData,
    sortModelData,
    setEditTable,
    editTable,
    setNewRow,
    filterModel,
    setFilterModel,
    columns,
    records,
    setRecords
  } = useServiceRunsContext();
  // const [records, setRecords] = useState<{ [key: string]: string }[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [filterObject, setFilterObject] = useState<{ [key: string]: string }>({});
  const { bu } = useGlobalContext();
  const defaultNewValues = useSetTableNewFieldsDefaults(data_fields);
  useCreateColumnsObject(
    data_fields, 
    form, 
    identityparams, 
    data, 
    dynamicHeaderExceptions, 
    funcData, 
    minDateRestriction, 
    maxDateRestriction
  );
  useSetPreQuery(form.setFieldValue, data && "tabledata" in data ? data.user_exist : false)
  const initialState = useGetColumnInitialState(data_fields);
  const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams);

  useEffect(() => {

    if (checkboxSelection) form.setFieldValue('jsonID.rowSelection', records.filter(r => rowSelectionModel.includes(r.id)))
  }, [rowSelectionModel])

  // useEffect(() => {
  //   console.log(columns,"columns")
  //   setSortModelData([{ field: data_fields[0].id, sort: 'desc' as GridSortDirection }])
  // }, [form.values.serviceID, columns])


  //   setSortModelData([{ field: data_fields[0].id, sort: 'desc' as GridSortDirection }])
  // }, [data_fields[0].id])

  useEffect(() => {
  
    // set !editTable in order to escape running when click on row. It cause problems in useCheckQueryReady
    if (queryReady && !editTable && service_descriptive_name === form.values.serviceID && records.length === 0) {
      if (funcQueryName) {
        funcTrigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" })
      } else {
        trigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" })
      }

    }

  }, [JSON.stringify(queryValuesObject), records, service_descriptive_name])
  // we trigger the query only on queryValuesObject changes

  useEffect(() => {

    if (filterBy) {
      let localFilteredObject: { [key: string]: string } = {};
      filterBy.forEach(filter => localFilteredObject[filter] = form.values.jsonID[filter])
      if (JSON.stringify(localFilteredObject) !== JSON.stringify(filterObject)) {
        setFilterObject(localFilteredObject)
      }
    }


  }, [form.values])

  useEffect(() => {

    if (data?.tabledata) {
      if (filterBy) {
        if (filterBy.every(filter => !["", "default", undefined].includes(form.values.jsonID[filter]))) {
          setRecords(data!.tabledata.filter(entry => filterBy.every(filter => entry[filter] === form.values.jsonID[filter])))
        } 
      } else {
        setRecords(data!.tabledata)
      }
    }
  }, [filterObject, data])
  
  useEffect(() => {

    if (funcData) {
      if (filterBy) {
        if (filterBy.every(filter => !["", "default", undefined].includes(form.values.jsonID[filter]))) {
          setRecords(funcData!.filter(entry => filterBy.every(filter => entry[filter] === form.values.jsonID[filter])))
        } 
      } else {
        setRecords(funcData!)
      }
    }
  }, [filterObject, funcData])

  useEffect(() => {

    setRecords([])
  }, [bu, form.values.serviceID, JSON.stringify(queryValuesObject)])

  const handleRowClick = useCallback((params: { row: { can_click: boolean; service_descriptive_name: string; } }) => {
    if (params.row.can_click) {
      setEditTable(true)
      navigate(`/services/createservicerun/${form.values.serviceID}`, {
        state: { service_descriptive_name: form.values.serviceID, json_payload: { ...form.values.jsonID, ...params.row } }
      });
    }
  }, [form.values.serviceID])

  const newRecord = () => {
    setNewRow(true)
    navigate(`/services/createservicerun/${form.values.serviceID}`, {
      state: { service_descriptive_name: form.values.serviceID, json_payload: { ...form.values.jsonID, ...defaultNewValues } }
    });
  }

  return (
    <>
      <TableComponent
        columns={columns}
        filteredData={useMemo(() => (records), [records])}
        loading={isFetching || isLoading || funcIsLoading || funcIsFetching}
        handleRowClick={handleRowClick}
        newRecord={newRecord}
        paginationModel={useMemo(() => paginationModel, [paginationModel])}
        onPaginationModelChange={setPaginationModel}
        sortModelData={useMemo(() => sortModelData, [sortModelData])}
        onSortModelDataChange={setSortModelData}
        newRecordLabel={newRecordLabel}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        initialState={initialState}
        hideToolBar={hideToolBar}
        disableTableEdit={disableTableEdit}
        checkboxSelection={checkboxSelection}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        disableColumnMenu={disableColumnMenu}
        disablePagination={disablePagination}
      />
    </>
  )
}
